<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import TableFilter from "@/components/modals/price-list-compare/table-filter.vue";
import TablePriceFilter from "@/components/modals/price-list-compare/table-price-filter.vue";
import PriceListFavoriteBtn from "./price-list-favorite-btn.vue";
import PriceListAlertBtn from "./price-list-alert-btn.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";

const urlItemsByPrice = `PriceListLineItem`;
const urlItemsByProduct = `PriceListLineItem/AlternativeList`;

export default {
  components: {
    PriceListItems,
    TableFilter,
    TablePriceFilter,
    PriceListFavoriteBtn,
    PriceListAlertBtn,
    ShowPharmacyRequirements,
  },

  data: (vm) => ({
    ln: "price_list.compare",
    uniqKey: "price-list-compare",
    uniqKey2: "price-list-compare-2",

    selected2: {},

    isFilter: false,
    filterData: vm.$getFilterData() || {},

    isPriceFilter: false,
    priceFilterData: vm.$getFilterData("price_list") || {},

    sortBy: "productName",
    sortBy2: "amount",

    urlItemsByPrice,
    urlItemsByProduct,

    isShowAnalog: null,
    isShowReplacement: null,

    isShowFavorite: undefined,
    isShowAlert: undefined,
    isShowOrganizationStatistic: undefined,
    isShowOrganizationStatisticReject: undefined,

    isMyMarkup: false,
    markupText: null,

    isShowItems: false,
    isInit: false,
    isDrawer: (function () {
      const drawer = localStorage.getItem("is-compare-drawer");
      if (drawer == "true") return true;
      else if (drawer == "false") return false;
    })(),
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  async created() {
    if (this.GET_USER.isTrial) {
      this.$store.dispatch("SET_LOADING", true);
      await this.GET_PERMISSIONS_LIST();
      this.$store.dispatch("SET_LOADING", false);

      const tryCount = this.$getObjectParam("CanPriceListCompare", this.GET_USER_PERMISSIONS);

      if (this.$number(tryCount)) {
        await this.GET_REDUCE_TRY_COUNT({ name: "CanPriceListCompare" });
        this.$nextTick(() => (this.isInit = true));
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("permission_confirm_dialog.permission_alert"),
          color: "error",
        });
        this.$router.push("/price-list");
      }
    }
    await this.GET_BY_GUID_API({ guid: this.priceListGuid });
    this.$nextTick(() => (this.isInit = true));
  },

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),

    ...mapGetters("price-list", { priceListObject: "GET_BY_GUID" }),
    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET(urlItemsByPrice),
    entriesCount: (vm) => vm.GET_COUNT(urlItemsByPrice),
    entriesTotals: (vm) => vm.GET_TOTALS(urlItemsByPrice),

    entriesByProduct: (vm) => vm.GET(urlItemsByProduct),
    entriesCountByProduct: (vm) => vm.GET_COUNT(urlItemsByProduct),
    entriesTotalsByProduct: (vm) => vm.GET_TOTALS(urlItemsByProduct),

    tableHeight() {
      return this.size.height - (this.priceListObject.isCurrent ? 210 : 300);
    },

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: "#",
          value: "favorite-and-alert",
          width: 10,
          sortable: false,
          _slot: true,
          _resize: false,
          align: "center",
          class: "disable-resize",
        },
        {
          text: vm.tn("price.table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("price.table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _required: true,
        },
        {
          text: vm.tn("price.table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("price.table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("price.table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("price.table.load"),
          value: "load",
        },
        {
          text: vm.tn("price.table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    table2Headers: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.priceProvider"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _html: true,
          _slot: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = vm.selected.amountDiscount || vm.selected.amount;
            const b = item.amount;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            if (diff) {
              let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
              if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
              return `${vl} ${val}`;
            }

            return `${val}`;
          },
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = vm.selected.amountDiscount || vm.selected.amount;
            const b = item.amountDiscount;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            if (diff) {
              let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
              if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
              return `${vl} ${val}`;
            }

            return `${val}`;
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.priceListDateTime"),
          value: "priceListDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes2[x.value]) x.width = vm.tdSizes2[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    table2ActionsList: (vm) => [
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => {
          window.open(`/price-list/${item.priceListGuid}`, "_blank");
        },
        icon: "mdi-information",
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => vm.$windowOpen("price-list-provider", item.priceListProviderGuid, item.priceListProviderName, 650, 800, "preview=1"),
        icon: "mdi-information",
      },
    ],

    favoriteBtnTitle() {
      if (this.isShowFavorite) {
        return this.tn("favorite.show_not_favorite_btn");
      } else if (this.isShowFavorite == false) {
        return this.tn("favorite.show_all_btn");
      } else {
        return this.tn("favorite.show_favorite_btn");
      }
    },

    alertBtnTitle() {
      if (this.isShowAlert) {
        return this.tn("alert.show_not_alert_btn");
      } else if (this.isShowAlert == false) {
        return this.tn("alert.show_all_btn");
      } else {
        return this.tn("alert.show_alert_btn");
      }
    },

    organizationStatisticBtnTitle() {
      if (this.isShowOrganizationStatistic) {
        return this.tn("organization_statistic.show_not_organization_statistic_btn");
      } else if (this.isShowOrganizationStatistic == false) {
        return this.tn("organization_statistic.show_all_btn");
      } else {
        return this.tn("organization_statistic.show_organization_statistic_btn");
      }
    },

    organizationStatisticRejectBtnTitle() {
      if (this.isShowOrganizationStatisticReject) {
        return this.tn("organization_statistic_reject.show_not_organization_statistic_reject_btn");
      } else if (this.isShowOrganizationStatisticReject == false) {
        return this.tn("organization_statistic_reject.show_all_reject_btn");
      } else {
        return this.tn("organization_statistic_reject.show_organization_statistic_reject_btn");
      }
    },

    priceListGuid: (vm) => vm.$route.params.guid,

    isFilterBadge() {
      const values = Object.values(this.filterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },

    isPriceFilterBadge() {
      const values = Object.values(this.priceFilterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },

    onDrawerValue() {
      let className = null;
      switch (`${this.isDrawer}`) {
        case "true": {
          className = "left-side-closed";
          break;
        }
        case "false": {
          className = "right-side-closed";
          break;
        }
      }

      return className;
    },
  },

  watch: {
    selected(v) {
      this.$refs.table2?.getEntries({ reset: true });
      this.isShowItems = false;
      this.$nextTick(() => {
        if (v?.linkedProductGuid) this.isShowItems = true;
        this.$refs.table3?.getEntries({ reset: true });
      });
    },
  },

  methods: {
    ...mapActions("auth", ["GET_PERMISSIONS_LIST", "GET_REDUCE_TRY_COUNT"]),

    ...mapActions("price-list", ["GET_BY_GUID_API"]),
    ...mapActions("price-list-items", ["GET_API"]),

    getPrice(params) {
      const filterData = { ...this.priceFilterData };
      if (!`${filterData.shelfLifeFrom || ""}`.trim()) delete filterData.shelfLifeFrom;
      params.filterData = filterData;
      params.filterData.isAlert = this.isShowAlert;
      params.filterData.isFavorite = this.isShowFavorite;
      params.filterData.showByPriceListViewerOrganizationStatistic = this.isShowOrganizationStatistic;
      params.filterData.showByPriceListViewerProductRejects = this.isShowOrganizationStatisticReject;
      return this.GET_API(params);
    },

    getItemsByProduct(params) {
      params.options.priceListLineItemGuid = this.selected.priceListLineItemGuid;
      params.filterData = this.filterData;
      params.isCancel = !params.options.priceListLineItemGuid;
      params.options.isShowAnalog = this.isShowAnalog;
      params.options.isShowReplacement = this.isShowReplacement;

      return this.GET_API(params);
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.$refs.table2?.getEntries({ reset: true });
    },

    onPriceFilter(priceFilterData) {
      this.priceFilterData = priceFilterData;
      this.$setFilterData(priceFilterData, "price_list");
      this.$refs.table1?.getEntries({ reset: true });
    },

    onTab() {
      if (this.$refs.table2.checkFocused()) {
        this.$refs.table1.isSavePosition = true;
        this.$refs.table1.setFocusToItem();
      } else {
        this.$refs.table2.setFocusToItem();
      }
    },

    onShowAnalog() {
      this.isShowAnalog = !this.isShowAnalog;
      this.$refs.table2?.getEntries({ reset: true });
    },

    onShowReplacement() {
      this.isShowReplacement = !this.isShowReplacement;
      this.$refs.table2?.getEntries({ reset: true });
    },

    getRowStyle(item) {
      if (item.productState == 2) return { backgroundColor: "#cdfad4" };
      else if (item.productState == 3) return { backgroundColor: "#f5facd" };
      else return { backgroundColor: "#fff" };
    },

    onShowFavorite() {
      if (this.isShowFavorite) this.isShowFavorite = false;
      else if (this.isShowFavorite == false) this.isShowFavorite = undefined;
      else this.isShowFavorite = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowAlert() {
      if (this.isShowAlert) this.isShowAlert = false;
      else if (this.isShowAlert == false) this.isShowAlert = undefined;
      else this.isShowAlert = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowOrganizationStatistic() {
      if (this.isShowOrganizationStatistic) this.isShowOrganizationStatistic = false;
      else if (this.isShowOrganizationStatistic == false) this.isShowOrganizationStatistic = undefined;
      else this.isShowOrganizationStatistic = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowOrganizationStatisticReject() {
      if (this.isShowOrganizationStatisticReject) this.isShowOrganizationStatisticReject = false;
      else if (this.isShowOrganizationStatisticReject == false) this.isShowOrganizationStatisticReject = undefined;
      else this.isShowOrganizationStatisticReject = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onDrawer() {
      if (this.isDrawer == true) {
        this.isDrawer = false;
        localStorage.setItem("is-compare-drawer", JSON.stringify(false));
      } else if (this.isDrawer == false) {
        this.isDrawer = undefined;
        localStorage.removeItem("is-compare-drawer");
      } else {
        this.isDrawer = true;
        localStorage.setItem("is-compare-drawer", JSON.stringify(true));
      }
    },
  },
};
</script>

<template>
  <v-container
    v-if="isInit"
    fluid
  >
    <TableFilter
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />

    <TablePriceFilter
      v-if="isPriceFilter"
      :value="priceFilterData"
      @close="isPriceFilter = false"
      @apply="onPriceFilter"
      :ln="ln + '.price.table.filter'"
    />

    <price-list-items
      :GET_API="getPrice"
      :entries="entries"
      @select="(v) => (selected = v)"
      @markup="(v) => (markupText = v)"
      @isMyMarkup="(v) => (isMyMarkup = v)"
      :entriesCount="entriesCount"
      :tableHeaders="tableHeaders"
      :ln="ln + '.price'"
      :sortBy="sortBy"
      :tableHeight="tableHeight / 2.14"
      :uniqKey="uniqKey"
      :url="urlItemsByPrice"
      disableTab
      ref="table1"
      isShowTotals
      :searchBoxHotKey="113"
      :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
    >
      <template #favorite-and-alert="slotProps">
        <div class="d-flex align-center">
          <price-list-favorite-btn
            :ln="ln"
            :item="slotProps.item"
          />
          <price-list-alert-btn
            :ln="ln"
            :item="slotProps.item"
          />

          <v-btn
            v-if="slotProps.item.hasStatistics"
            color="transparent"
            icon
            x-small
          >
            <img
              width="19"
              height="19"
              src="@/assets/svg/potrebnost.svg"
              alt=""
            />
          </v-btn>

          <v-btn
            v-if="slotProps.item.hasRejects"
            color="primary"
            icon
            x-small
          >
            <img
              src="@/assets/svg/reject.svg"
              alt=""
              width="19"
              height="19"
            />
          </v-btn>
        </div>
      </template>
      <template #title>
        <h3>
          <v-icon
            small
            @click="$windowOpen('price-list-provider', priceListObject.providerGuid, priceListObject.providerName, 650, 800, 'preview=1')"
          >
            mdi-information-outline
          </v-icon>

          {{ priceListObject.providerName }}
        </h3>
        <small>
          <b> {{ tn("updated") }}: </b>
          {{ priceListObject.timePublished | dateTime }};
          <b> {{ tn("expiryDate") }}: </b>
          {{ priceListObject.expiryDate | date }}
        </small>
      </template>

      <template #actions>
        <div class="px-1" />
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="favoriteBtnTitle"
          @click="onShowFavorite"
        >
          <v-icon v-if="isShowFavorite"> mdi-star </v-icon>
          <v-icon v-else-if="isShowFavorite == undefined"> mdi-star-half-full </v-icon>
          <v-icon v-else> mdi-star-outline </v-icon>
        </v-btn>

        <div class="mx-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="alertBtnTitle"
          @click="onShowAlert"
        >
          <v-icon v-if="isShowAlert"> mdi-bell </v-icon>
          <img
            v-else-if="isShowAlert == undefined"
            src="@/assets/svg/mdi-bell-outline.svg"
            alt=""
          />
          <v-icon v-else> mdi-bell-outline </v-icon>
        </v-btn>

        <div class="px-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="organizationStatisticBtnTitle"
          @click="onShowOrganizationStatistic"
        >
          <img
            v-if="isShowOrganizationStatistic"
            src="@/assets/svg/potrebnost.svg"
            alt=""
          />
          <img
            v-else-if="isShowOrganizationStatistic == undefined"
            src="@/assets/svg/potrebnost-off.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/svg/potrebnost-outlined.svg"
            alt=""
          />
        </v-btn>

        <div class="px-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="organizationStatisticRejectBtnTitle"
          @click="onShowOrganizationStatisticReject"
        >
          <img
            v-if="isShowOrganizationStatisticReject"
            src="@/assets/svg/reject.svg"
            alt=""
          />
          <img
            v-else-if="isShowOrganizationStatisticReject == undefined"
            src="@/assets/svg/reject-off.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/svg/reject-outlined.svg"
            alt=""
          />
        </v-btn>

        <div class="mx-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="tn('show_additional_info')"
          @click="onDrawer"
        >
          <v-icon v-if="isDrawer"> mdi-form-dropdown </v-icon>
          <v-icon v-else-if="isDrawer == undefined"> mdi-google-circles-extended </v-icon>
          <v-icon v-else> mdi-form-select </v-icon>
        </v-btn>

        <v-badge
          class="ml-2"
          dot
          overlap
          color="error"
          :value="isPriceFilterBadge"
        >
          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            @click="isPriceFilter = true"
            :title="tn('filter_btn')"
          >
            <v-icon> mdi-filter </v-icon>
          </v-btn>
        </v-badge>
      </template>

      <div
        v-if="priceListObject.isCurrent == false"
        class="my-1 error px-3 py-2 white--text price-list-show-expiry-alert"
      >
        <div>
          <div class="title">
            {{ tn("expiry_date_alert") }}
          </div>
          <template v-if="priceListObject.currentPriceListGuid">
            {{ tn("has_new_price_alert") }}
          </template>
        </div>
        <div>
          <v-btn
            v-if="priceListObject.currentPriceListGuid"
            elevation="0"
            class="transform-none"
            :to="`/price-list/compare/${priceListObject.currentPriceListGuid}`"
            replace
          >
            {{ tn("show_prays_btn") }}
          </v-btn>
        </div>
      </div>

      <template #body-append="bodyProps">
        <td
          v-for="(header, i) in bodyProps.headers"
          :key="i"
        >
          <div
            v-if="header.value == 'number'"
            class="text-nowrap text-center"
          >
            {{ entriesCount | sum }}
          </div>

          <div
            v-else-if="header.value == 'amount'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalAmount | sum }}
          </div>

          <div
            v-else-if="header.value == 'actions'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalQuantity | sum }}
          </div>

          <div v-else />
        </td>
      </template>
    </price-list-items>

    <div class="pt-1 pb-2" />

    <v-divider class="mx-8" />

    <div class="py-1" />

    <div class="page-wrap">
      <div
        :class="isDrawer && onDrawerValue"
        class="left-side"
      >
        <price-list-items
          :GET_API="getItemsByProduct"
          :entries="entriesByProduct"
          @select="(v) => (selected2 = v)"
          @markup="(v) => (markupTextAlternative = v)"
          @isMyMarkup="(v) => (isMyMarkupAlternative = v)"
          :entriesCount="entriesCountByProduct"
          :tableHeaders="table2Headers"
          :ln="ln"
          :sortBy="sortBy2"
          :tableHeight="tableHeight / 2.25"
          :uniqKey="uniqKey2"
          :url="urlItemsByProduct"
          :isShowMyMarkup="false"
          hideBackBtn
          disableAutofocus
          ref="table2"
          class="left-side-list"
          @tab="onTab"
          :activeItem="selected"
          :actionsList="table2ActionsList"
          :sort="false"
          isShowTotals
          :getRowStyle="getRowStyle"
          :v-resize-table="{ tableHeaders: table2Headers, callback: onUpdateTdSizes2 }"
        >
          <template #title>
            <h3
              style="text-wrap: nowrap; overflow-x: hidden; text-overflow: ellipsis"
              :style="{ maxWidth: isDrawer == null ? '385px' : '100%' }"
            >
              {{ selected.productName }}
              ({{ selected.manufacturerName }})
            </h3>
            <small>
              <b>{{ tn("price_type") }}: </b> {{ selected.priceTypeName }};
              <b>{{ tn("amount") }}:</b> {{ selected.amount | sum }}
            </small>
          </template>

          <template #actions-prepend="slotProps">
            <v-btn
              :color="isShowAnalog ? '#cdfad4' : 'primary'"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              :outlined="!isShowAnalog"
              elevation="0"
              @click="onShowAnalog"
              :disabled="slotProps.loading"
              :loading="slotProps.loading"
              :title="tn('show_analog_btn')"
              class="mr-2"
            >
              <v-icon :color="isShowAnalog ? 'primary' : ''"> А </v-icon>
            </v-btn>

            <v-btn
              :color="isShowReplacement ? '#f5facd' : 'primary'"
              min-width="40"
              width="40"
              height="40"
              min-height="40"
              :outlined="!isShowReplacement"
              elevation="0"
              @click="onShowReplacement"
              :disabled="slotProps.loading"
              :loading="slotProps.loading"
              :title="tn('show_replacement_btn')"
              class="mr-2"
            >
              <v-icon :color="isShowReplacement ? 'primary' : ''"> З </v-icon>
            </v-btn>
          </template>

          <template #productName="slotProps">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="text-left px-1"
                  v-bind="attrs"
                  v-on="on"
                  v-text="slotProps.item.productName"
                />
              </template>
              <span v-if="slotProps.item.productState == 1">{{ tn("original") }}</span>
              <span v-else-if="slotProps.item.productState == 2">{{ tn("analog") }}</span>
              <span v-else-if="slotProps.item.productState == 3">{{ tn("replacement") }}</span>
            </v-tooltip>
          </template>

          <template #actions>
            <v-badge
              class="ml-3"
              dot
              overlap
              color="error"
              :value="isFilterBadge"
            >
              <v-btn
                color="primary"
                min-width="40"
                width="40"
                height="40"
                min-height="40"
                outlined
                @click="isFilter = true"
                :title="tn('filter_btn')"
              >
                <v-icon> mdi-filter </v-icon>
              </v-btn>
            </v-badge>
          </template>

          <template #body-append="bodyProps">
            <td
              v-for="(header, i) in bodyProps.headers"
              :key="i"
            >
              <div
                v-if="header.value == 'number'"
                class="text-nowrap text-center"
              >
                {{ entriesCountByProduct | sum }}
              </div>

              <div
                v-else-if="header.value == 'amount'"
                class="text-nowrap text-right"
              >
                {{ entriesTotalsByProduct.totalAmount | sum }}
              </div>

              <div
                v-else-if="header.value == 'actions'"
                class="text-nowrap text-right"
              >
                {{ entriesTotalsByProduct.totalQuantity | sum }}
              </div>

              <div v-else />
            </td>
          </template>
        </price-list-items>
      </div>

      <div
        v-if="isDrawer == undefined"
        class="mx-4"
        style="height: tableHeight / 2.23; width: 1px; background-color: #0000001f"
      />

      <div
        class="right-side"
        :class="!isDrawer && onDrawerValue"
      >
        <ShowPharmacyRequirements
          v-if="isShowItems"
          ref="table3"
          class="right-side-list"
          :tableHeight="tableHeight / 2.26"
          :productGuid="selected?.linkedProductGuid"
          :amount="selected2?.amountDiscount || selected2?.amount || selected?.amountDiscount || selected?.amount"
          :uniqKey="ln"
          :isShowSearchBtn="false"
          :isHideBtn="false"
          @isHide="(e) => (isHide = e)"
        />
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    flex: 2;
    overflow-y: auto;
    transition: all 0.2s;

    &.left-side-closed {
      flex: 0;

      .left-side-list {
        max-height: 43vh;
      }
    }
  }

  .right-side {
    flex: 2;
    overflow-y: auto;
    transition: all 0.2s;

    &.right-side-closed {
      flex: 0;

      .right-side-list {
        max-height: 43vh;
      }
    }
  }
}
</style>
