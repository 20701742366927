import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  historyData: {},

  historyItem: {},
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.historyData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },

  SET_BY_GUID(state, payload) {
    state.historyItem = payload || {};
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterData = {} }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_ITEMS");
    const params1 = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      sortBy: options.sortBy,
      sortDirection: options.sortDesc,
      search: options.search,
      ...filterData,
    };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(
      `Basket/HistoryList/${params.guid}/Items`,
      params,
      request
    );
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async GET_BY_GUID_API({ commit }, { guid }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();
    commit("SET_BY_GUID");

    const res = await vm.$axios.$get("Basket/" + guid);
    if (res?.succeeded) commit("SET_BY_GUID", res?.data);
  },
};

export const getters = {
  GET: (state) => state.historyData.items,
  GET_COUNT: (state) => state.historyData.total,
  GET_BY_GUID: (state) => state.historyItem,
};
