import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  priceListProvidersData: {},
  basketByGuid: {},
  basketLineItem: {},
});

export const mutations = {
  SET_PRICE_LIST_PROVIDERS_DATA(state, payload) {
    const data = payload?.data;

    state.priceListProvidersData = {
      items: vm.$array(data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(data?.totalCount),
      totals: {
        totalProviderCount: vm.$number(data?.totalProviderCount),
        totalAmount: vm.$number(data?.totalAmount),
        totalItemsCount: vm.$number(data?.totalItemsCount),
      },
    };
  },

  SET_BASKET_BY_GUID(state, payload) {
    state.basketByGuid = payload || {};
  },

  SET_LINE_ITEM_BY_GUID(state, payload) {
    state.basketLineItem = payload || {};
  },
};

export const actions = {
  async GET_PRICE_LIST_PROVIDERS_API(
    { commit },
    { options, isCancel, filterData }
  ) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_PRICE_LIST_PROVIDERS_DATA");
    if (isCancel) return;

    const request = { progress: false, cancelToken: cancelSource.token };

    const params1 = { ...options, ...filterData };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const res = await vm.$axios.$post("Basket/List", params, request);
    if (res?.succeeded)
      commit("SET_PRICE_LIST_PROVIDERS_DATA", { data: res?.data });
  },

  async CLOSE_BASKET_API(commit, payload) {
    const res = await vm.$axios.$post(`Basket/${payload.guid}/Close`, payload);
    return res?.succeeded;
  },

  async REMOVE_API(commit, { basketGuid }) {
    const res = await vm.$axios.$delete(`Basket/${basketGuid}`);
    return res?.succeeded;
  },

  async CHANGE_WITH_FRESH_LIST_API(commit, { basketGuid }) {
    const url = `BasketLineItem/ChangeWithFreshList`;
    const params = { basketGuid };
    const res = await vm.$axios.$post(url, params);
    return res?.succeeded;
  },

  async DELETE_SELECTED_BASKETS_API(commit, { basketGuids }) {
    const res = await vm.$axios.$post(`Basket/Delete`, { basketGuids });
    return res?.succeeded;
  },

  async GET_BASKET_BY_GUID_API({ commit }, { guid }) {
    commit("SET_BASKET_BY_GUID");
    const res = await vm.$axios.$get(`/Basket/${guid}`);
    if (res?.succeeded) commit("SET_BASKET_BY_GUID", res?.data);
  },

  async GET_LINE_ITEM_BY_GUID_API({ commit }, { guid }) {
    commit("SET_LINE_ITEM_BY_GUID");
    const res = await vm.$axios.$get(`BasketLineItem/${guid}`);
    if (res?.succeeded) commit("SET_LINE_ITEM_BY_GUID", res?.data);
  },
};

export const getters = {
  GET_PRICE_LIST_PROVIDERS: (state) =>
    vm.$array(state.priceListProvidersData?.items),
  GET_PRICE_LIST_PROVIDERS_COUNT: (state) =>
    state.priceListProvidersData?.total || 0,
  GET_TOTALS: (state) => state.priceListProvidersData.totals,

  GET_BASKET_BY_GUID: (state) => state.basketByGuid,
  GET_LINE_ITEM_BY_GUID: (state) => state.basketLineItem,
};
